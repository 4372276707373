import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../utils/global-styles/theme";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import RightGoToArrow from "../CourseCard/Icons/RightGoToArrow";

const DualColumnCard2 = ({ programs }) => {
  const Container = styled.div`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: 300px;
      
    `,
    CardImage = styled(Img)`
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        display: none;
      }
    `,
    CardDetails = styled.div`
      grid-area: 1 / 1 / span 1 / span 2;
      display: grid;
      grid-template-rows: 50px auto auto 50px;
      padding-left: 70px;
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        grid-area: 1 / 1 / span 1 / span 3;
        padding: 0 40px;
      }
    `,
    ProgramType = styled.p`
      grid-area: 1 / 1 / span 1 / 1;
      font-size: 1em;
      font-weight: normal;
      display: flex;
      align-items: center;
      font-family: ${theme.fontFaces.headers};
      color: ${theme.colors.main};
    `,
    ProgramTitle = styled.p`
      grid-area: 2 / 1 / span 2 / 1;
      font-family: ${theme.fontFaces.headers};
      color: ${theme.colors.main};
      font-size: 1.8em;
      line-height: 35px;
      letter-spacing: -0.91px;
    `,
    ProgramDate = styled.p`
      grid-area: 3 / 1 / 3 / 1;
      font-family: ${theme.fontFaces.headers};
      color: ${theme.colors.main};
      display: flex;
      align-items: center;
      font-size: 1.2em;
      font-weight: normal;
    `,
    ProgramLink = styled.a`
      border-bottom: 3px solid;
      color: ${theme.colors.main};
      margin-right: 5px;
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        border-bottom: none;
      }
    `,
    RightArrow = styled(RightGoToArrow)`
      display: none;
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        display: block;
        margin-left: 1em;
      }
    `,
    renderCards = () => {
      return programs.map((program, index) => {
        if (index % 2 === 0) {
          return (
            <Container className="primary-bg">
              <CardImage
                css={css`
                  grid-area: 1 / 3 / span 1 / span 3;
                `}
                fluid={program?.heroImage?.fluid}
              />
              <CardDetails
                css={css`
                  grid-area: 1 / 1 / span 1 / span 2;
                `}
              >
                <ProgramType>{program?.courseType}</ProgramType>
                <ProgramTitle>{program?.title}</ProgramTitle>
                <ProgramDate>{program?.courseStartDate}</ProgramDate>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <ProgramLink
                    target="_blank"
                    href={program?.cta?.url}
                    className="white"
                  >
                    {program?.cta?.linkText}
                  </ProgramLink>
                  <RightArrow
                    height="22.86px"
                    width="30px"
                    fillColor="main-fill"
                  />
                </div>
              </CardDetails>
            </Container>
          );
        } else {
          return (
            <Container className="secondary-bg main">
              <CardImage
                css={css`
                  grid-area: 1 / 1 / span 1 / span 1;
                `}
                fluid={program?.heroImage?.fluid}
              />
              <CardDetails
                css={css`
                  grid-area: 1 / 2 / span 1 / span 3;
                  padding-left: 0;
                  padding-right: 70px;
                  text-align: right;
                  @media only screen and (max-width: ${theme.breakpoints
                      .desktopUp}) {
                    text-align: left;
                  }
                `}
              >
                <ProgramType
                  css={css`
                    justify-content: flex-end;
                    @media only screen and (max-width: ${theme.breakpoints
                        .desktopUp}) {
                      justify-content: flex-start;
                    }
                  `}
                >
                  {program?.courseType}
                </ProgramType>
                <ProgramTitle>{program?.title}</ProgramTitle>
                <ProgramDate
                  css={css`
                    justify-content: flex-end;
                    @media only screen and (max-width: ${theme.breakpoints
                        .desktopUp}) {
                      justify-content: flex-start;
                    }
                  `}
                >
                  {program?.courseStartDate}
                </ProgramDate>
                <div
                  css={css`
                    @media only screen and (max-width: ${theme.breakpoints
                        .desktopUp}) {
                      display: flex;
                      align-items: center;
                    }
                  `}
                >
                  <ProgramLink
                    target="_blank"
                    href={program?.cta?.url}
                    className="main"
                  >
                    {program?.cta?.linkText}{" "}
                  </ProgramLink>
                  <RightArrow
                    height="22.86px"
                    width="30px"
                    fillColor="dkGray-fill"
                  />
                </div>
              </CardDetails>
            </Container>
          );
        }
      });
    };
  return (
    <section
      css={css`
        margin-bottom: 12em;
      `}
    >
      {renderCards()}
    </section>
  );
};

DualColumnCard2.propTypes = {};

export default DualColumnCard2;
