import React from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import FoundryHero from "../components/Foundry/child-components/FoundryHero";
import FoundryIntro from "../components/Foundry/child-components/FoundryIntro";
import AscendingCascadingCards from "../components/AscendingCascadingCards/AscendingCascadingCards";
import JoinTheFoundry from "../components/Foundry/child-components/JoinTheFoundry";
import FeaturedFoundryMembers from "../components/Foundry/child-components/FeaturedFoundryMembers";
import TestimonialWithImage from "../components/TestimonialWithImage/TestimonialWithImage";
import CourseBenefits from "../components/CourseDetail/child-components/CourseBenefits";
import DualColumnCard2 from "../components/DualColumnCard2/DualColumnCard2";
import CourseSyllabus from "../components/CourseDetail/child-components/CourseSyllabus/CourseSyllabus";
import SliderOverlay from "../components/shared/SliderOverlay/SliderOverlay";
import { theme } from "../utils/global-styles/theme";

const Foundry = ({ data, pageContext: { locale } }) => {
  const {
    heading,
    cascadingBenefitsSection,
    heroImage,
    shortDescription,
    subHeading,
    videoPreviewUrl,
    videoPreviewText,
    videoThumbnail,
    joinCardsSection,
    joinSectionHeader,
    featuredSectionHeader,
    featuredFoundryMembers,
    meetTheFoundryLink,
    testimonials,
    benefits,
    programsSectionHeader,
    programs,
    highlightsSectionHeader,
    highlightsSectionLink,
    highlights,
  } = data.foundry.nodes[0];

  const cards = [1, 2, 3].map(i => ({
    description: cascadingBenefitsSection["description" + i],
    benefit: cascadingBenefitsSection["benefit" + i],
  }));

  const ProgramsHeader = styled.h1`
    padding: 65px 80px 0 80px;
    line-height: 135px;
    @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
      padding: 47px 30px 0 30px;
      font-size: 100px;
      line-height: 105px;
    }

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      padding: 42px 30px 0 30px;
      font-size: 70px;
      line-height: 75px;
    }
  `;

  return (
    <Layout isDetailView={false} fullDeviceWidth locale={locale}>
      {heroImage.fluid && (
        <FoundryHero
          image={heroImage}
          heading={heading}
          subHeading={subHeading}
          locale={locale}
        />
      )}
      <FoundryIntro
        description={shortDescription.shortDescription}
        videoPreviewUrl={videoPreviewUrl}
        videoPreviewText={videoPreviewText}
        videoThumbnail={videoThumbnail}
      />
      {cards.length === 3 && <AscendingCascadingCards cards={cards} />}
      {joinCardsSection.length === 2 && (
        <JoinTheFoundry cards={joinCardsSection} heading={joinSectionHeader} marginBottom={100} />
      )}
      {featuredFoundryMembers.length && (
        <FeaturedFoundryMembers
          header={featuredSectionHeader}
          members={featuredFoundryMembers}
          link={meetTheFoundryLink}
        />
      )}
      {testimonials.length && (
        <TestimonialWithImage testimonials={testimonials} programs={programs} />
      )}
      {benefits.length && <CourseBenefits courseBenefits={benefits} />}
      {programs?.length >= 1 && (
        <>
          <ProgramsHeader>{programsSectionHeader}</ProgramsHeader>
          <DualColumnCard2 programs={programs} />
        </>
      )}
      {highlights.length && (
        <SliderOverlay
          data={highlights}
          title={highlightsSectionHeader}
          contentMultiple={3}
          arrowIncrementDecrement={3}
          titleOverlap={"5%"}
          arrowBgColorClassName="white"
          needsMobileBottomMargin={true}
          ctaText={highlightsSectionLink.linkText}
          ctaUrl={highlightsSectionLink.url}
        />
      )}
    </Layout>
  );
};

Foundry.propTypes = {};

export const query = graphql`
  query($locale: String!) {
    foundry: allContentfulFoundryPage(
      filter: { title: { eq: "Foundry Lander" }, node_locale: { eq: $locale } }
    ) {
      nodes {
        heading
        subHeading
        shortDescription {
          shortDescription
        }
        heroImage {
          fluid {
            srcSet
          }
        }
        videoPreviewUrl
        videoPreviewText
        videoThumbnail {
          fluid {
            srcSet
          }
        }
        cascadingBenefitsSection {
          benefit1
          description1
          benefit2
          description2
          benefit3
          description3
        }
        joinSectionHeader
        joinCardsSection {
          title
          description {
            description
          }
          link {
            linkText
            url
          }
        }
        featuredSectionHeader
        featuredFoundryMembers {
          name
          sectionTitle
          photoLogo {
            fluid {
              srcSet
            }
          }
          bioAbout {
            bioAbout
          }
          bioLong {
            bioLong
          }
          year
          region
        }
        meetTheFoundryLink {
          linkText
          url
        }
        testimonials {
          authorName
          authorTitle
          testimonialQuote {
            testimonialQuote
          }
          image {
            fluid {
              srcSet
            }
          }
        }
        benefits {
          icon1Image {
            fluid {
              srcSet
            }
          }
          description1
          benefit1
          icon2Image {
            fluid {
              srcSet
            }
          }
          description2
          benefit2
          icon3Image {
            fluid {
              srcSet
            }
          }
          description3
          benefit3
          icon4Image {
            fluid {
              srcSet
            }
          }
          description4
          benefit4
        }
        programsSectionHeader
        programs {
          ... on ContentfulAcceleratorDetailPage {
            courseType
            courseStartDate
            heroImage {
              fluid {
                srcSet
              }
            }
            title
            cta {
              linkText
              url
            }
          }
        }
        highlightsSectionHeader
        highlightsSectionLink {
          linkText
          url
        }
        highlights {
          foundryMember
          title
          readMoreLink {
            linkText
            url
          }
          image {
            fluid {
              srcSet
            }
          }
          videoPreviewUrl
          videoThumbnail {
            fluid {
              srcSet
            }
          }
        }
      }
    }
  }
`;

export default Foundry;
