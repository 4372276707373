import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";
import VideoPreview from "../../CourseDetailV2/VideoPreview";
import Markdown from "markdown-to-jsx";

const FoundryIntro = ({
  description,
  videoPreviewUrl,
  videoThumbnail,
  videoPreviewText,
}) => {
  const SectionContainer = styled.section`
      margin: 30px 200px 100px 200px;
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        margin: 50px 145px 100px 145px;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin: 30px 40px 100px 40px;
      }
    `,
    IntroText = styled(Markdown)`
      p {
        margin-bottom: 1rem;
      }
      margin-bottom: 4rem;
      font-size: 20px;
      line-height: 2rem;
      margin-right: 142px;
      margin-left: 142px;

      @media only screen and (min-width: ${theme.breakpoints
          .tabletUp}) and (max-width: ${theme.breakpoints.iPadPro}) {
        margin-right: 0px;
        margin-left: 0px;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin-bottom: 3rem;
        margin-right: 0px;
        margin-left: 0px;
      }
    `;
  return (
    <SectionContainer>
      <IntroText>{description || ""}</IntroText>
      {videoPreviewUrl && (
        <VideoPreview
          videoPreviewUrl={videoPreviewUrl}
          videoThumbnail={videoThumbnail}
          mobileTextSize={"25px"}
          height={"445px"}
          caption={videoPreviewText}
        />
      )}
    </SectionContainer>
  );
};

FoundryIntro.propTypes = {};

export default FoundryIntro;
