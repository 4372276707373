import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { theme } from "../../../utils/global-styles/theme";

const FoundryHero = ({ image, subHeading, heading, locale }) => {
  const HeroWrapper = styled.section`
      display: grid;
      grid-template: 35px 1fr 120px / 80px 1fr 120px;
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        grid-template: 35px auto 110px / 30px 1fr 30px;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-template: 35px auto 60px/30px 1fr 30px;
      }
    `,
    HeroImage = styled(Img)`
      grid-area: 1 / 1 / span 3 / span 3;
      height: 100%;
      width: 100%;
      z-index: 2;
    `,
    HeroData = styled.div`
      grid-area: 2 /2 / span 2/2;
      align-self: end;
      z-index: 3;
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        grid-area: 2 / 2 / 2 / 2;
        padding: 0;
      }
    `,
    HeroTitle = styled.h1`
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      }
    `,
    HeroSubtitle = styled.p`
    font-size: 35px;
    font-family: ${theme.fontFaces.headers};
    font-weight: bold;
    letter-spacing: -0.9px;
    line-height: 43px;
    margin: 30px auto 50px auto;
    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 30px;
        letter-spacing: -0.52px;
        line-height: 35px;
      }
    }
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 20px;
      letter-spacing: -0.52px;
      line-height: 24px;
    }
  }
  `,
    LanguageToggle = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      display: flex;
      z-index: 5;
      align-items: baseline;
      align-self: center;
      margin-bottom: 50px;
      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        padding: 0;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-area: 1 / 1 / span 1 / span 1;
        padding: 15px 15px;
        align-self: center;
      }
    `,
    LanguageText = styled.span`
      text-transform: uppercase;
      margin-right: 27px;
      font-size: 16px;
      font-weight: bold;
      font-weight: bold;
      font-family: ${theme.fontFaces.headers};
      border-bottom-style: ${props => (props.isHighlight ? "solid" : "none")};
      border-bottom-width: 2px;
      cursor: pointer;
    `,
    redirect = url => {
      window.location.href = url;
    };

  return (
    <HeroWrapper>
      <HeroImage fluid={image.fluid} />
      <HeroData>
        {locale === "en-US" && (
          <LanguageToggle>
            <LanguageText
              isHighlight={true}
              className="primary primary-border"
              onClick={() => redirect("/foundry")}
            >
              English
            </LanguageText>
            <LanguageText
              className="jungle-green jungle-green-hover"
              onClick={() => redirect("/foundry/es/")}
            >
              Español
            </LanguageText>
          </LanguageToggle>
        )}
        {locale === "es" && (
          <LanguageToggle>
            <LanguageText
              isHighlight={true}
              className="primary primary-border"
              onClick={() => redirect("/foundry/es")}
            >
              Español
            </LanguageText>
            <LanguageText
              className="jungle-green jungle-green-hover"
              onClick={() => redirect("/foundry/")}
            >
              Ingles
            </LanguageText>
          </LanguageToggle>
        )}
        <HeroTitle className="codedH1 white">
          {heading || "Fellowship"}
        </HeroTitle>
        <HeroSubtitle className="white">{subHeading || ""}</HeroSubtitle>
      </HeroData>
    </HeroWrapper>
  );
};

FoundryHero.propTypes = {};

export default FoundryHero;
